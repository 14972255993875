<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-topic', params: { accountId: this.$route.params.accountId } }">Topics</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ topic.label }}</h1>
                <p class="text-caption text-center">Topic overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="topic">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end mb-0 grey--text">Topic {{ topic.id }}</p>
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="teal darken-3" dark>
                            <v-toolbar-title>{{ topic.label }}</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">

                            <p class="text-overline mb-0 mt-2">Label</p>
                            <p class="mb-0 pb-0">
                                {{ topic.label }}
                                <v-btn icon color="teal darken-2" @click="editTopicLabel">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>

                            <p class="text-overline mb-0 mt-2">
                                Visibility
                                <v-btn icon @click="displayVisibilityHelp = !displayVisibilityHelp" color="teal darken-2">
                                    <font-awesome-icon :icon="['fas', 'question-circle']"/>
                                </v-btn>
                            </p>
                            <template v-if="displayVisibilityHelp">
                                <p class="mb-0 pb-0">
                                    Should this topic be visible in your subscribe page for people to opt-in themselves?
                                </p>
                                <p class="mb-0 pb-0 mt-2">
                                    Usually this should be allowed. You might want to uncheck this box if you have a special topics that are used only for some contacts and should not be visible to all contacts, and you have a process for automatically or interactively adding such a topic for a contact. If a topic is hidden and a contact unsubscribes, they won't be able to subscribe to it again by themselves, they will need to be added by you.
                                </p>
                                <p class="mb-0 pb-0 mt-2">
                                    When a contact is subscribed to topic, this setting does not apply because it must appear in their email preferences page to allow opt-out.
                                </p>
                            </template>
                            <p class="mb-0 pb-0">
                                <v-checkbox v-model="editableAllowInbound" label="Show topic in the subscribe page" @change="saveAllowInbound" class="mt-0"></v-checkbox>
                            </p>

                            <p class="text-overline mb-0 mt-8">Comment</p>
                            <p class="mb-0 pb-0">
                                {{ topic.comment }}
                                <v-btn icon color="teal darken-2" @click="editTopicComment">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Topic ID</p>
                            <p class="mb-0 pb-0">
                                {{ topic.id }}
                            </p> -->

                            <!-- TODO: the affected X list should depend on the topic type... instead of hard-coding email contacts -->
                            <p class="text-overline mb-0 mt-8">Status</p>
                            <p class="mb-0 pb-0">
                                <span v-if="affectedEmailContactList.length > 0">
                                    This topic is subscribed by {{ affectedEmailContactList.length }} email contacts.
                                    <!-- TODO: show the affectedEmailContactList -->
                                </span>
                                <span v-if="affectedEmailContactList.length === 0">
                                This topic is not currently applied to any email contacts.
                                </span>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Security</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, topicId: this.$route.params.topicId } }">Access control</router-link>
                            </p> -->
                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                            <p class="mb-0 pb-0">
                            </p> -->
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Topic Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editTopicLabelDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the topic label</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The topic label informs users about the content of communications they will receive if they subscribe to it.
                            You can change it at any time, but the new meaning should be similar to the old one. It IS shown to users.
                        </p>

                        <v-text-field
                            ref="topicLabelInput"
                            v-model="editableTopicLabel"
                            label="Label"
                            :rules="newTopicLabelRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            type="text"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditTopicLabel" :disabled="!isEditTopicLabelComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editTopicLabelDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editTopicCommentDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the topic comment</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The topic comment is a place where you can add more information for your own reference about how you use the topic.
                            You can change it at any time. It is NOT shown to users.
                        </p>
                        <v-textarea v-model="editableTopicComment" label="Topic comment"></v-textarea>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditTopicComment" :disabled="!isEditTopicCommentComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editTopicCommentDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import TopicList from '@/components/account-dashboard/TopicList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // TopicList,
    },
    data: () => ({
        affectedEmailContactList: [],

        error: null,
        account: null,
        topic: null,
        invite: null,

        displayVisibilityHelp: false,
        editableAllowInbound: null,

        // edit form title dialog
        editTopicLabelDialog: false,
        editTopicCommentDialog: false,
        editableTopicComment: null,

        editableTopicLabel: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.form !== null;
        },
        isEditTopicLabelComplete() {
            return typeof this.editableTopicLabel === 'string' && this.editableTopicLabel.trim().length > 0;
        },
        isEditTopicCommentComplete() {
            return typeof this.editableTopicComment === 'string' && this.editableTopicComment.trim().length > 0;
        },
    },
    // watch: {
    //     topic(newValue) {
    //         if (newValue && typeof newValue.allow_inbound === 'boolean') {
    //             this.editableAllowInbound = newValue.allow_inbound;
    //         }
    //     },
    // },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadTopic() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadTopic: true });
                const response = await this.$client.account(this.$route.params.accountId).topic.get(this.$route.params.topicId);
                console.log(`loadTopic: response ${JSON.stringify(response)}`);
                if (response) {
                    this.topic = response;
                    this.editableAllowInbound = response.allow_inbound ?? false;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.topic.comment ??= '';
                    // this.topic.reply_to ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load topic');
                }
            } catch (err) {
                console.error('failed to load topic', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadTopic: false });
            }
        },
        async save(topicAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditTopic: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).topic.edit({ id: this.$route.params.topicId }, topicAttr);
                console.log(`saveEditTopic: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit topic' });
                return false;
            } catch (err) {
                console.error('failed to edit topic', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit topic' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditTopic: false });
            }
        },
        editTopicLabel() {
            this.editTopicLabelDialog = true;
            this.editableTopicLabel = this.topic.label;
        },
        editTopicComment() {
            this.editTopicCommentDialog = true;
            this.editableTopicComment = this.topic.comment;
        },
        async saveEditTopicLabel() {
            const isEdited = await this.save({ label: this.editableTopicLabel });
            this.editTopicLabelDialog = false;
            if (isEdited) {
                this.$set(this.topic, 'label', this.editableTopicLabel);
            }
        },
        async saveEditTopicComment() {
            const isEdited = await this.save({ comment: this.editableTopicComment });
            this.editTopicCommentDialog = false;
            if (isEdited) {
                this.$set(this.topic, 'comment', this.editableTopicComment);
            }
        },
        async saveAllowInbound() {
            const isEdited = await this.save({ allow_inbound: this.editableAllowInbound });
            if (isEdited) {
                this.$set(this.topic, 'allow_inbound', this.editableAllowInbound);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadTopic();
    },
};
</script>
